<template>
  <div>
    <div class="topButton">
      <CRow>
        <CCol sm="4">
          <div class="titleFont floatLeft">角色管理</div>
        </CCol>
        <CCol sm="8">
          <!-- <CButton
            variant="outline"
            class=" floatRight rbutton"
            color="primary"
            @click="rolePop = true"
          >
            建立角色
          </CButton> -->
        </CCol>
      </CRow>
    </div>
    <div class="clearBoth"></div>
    <CCard>
      <CCardBody>
        <div class="positionR">
          <div class="tableTitle">
            <div role="group" class="projectSelect form-group"></div>
          </div>
        </div>
        <CDataTable
          :items="exchangeRate"
          :fields="fields"
          :sorter="{ external: true }"
          @update:sorter-value="handlerSorterValueChange"
          hover
          :noItemsView="{ noItems: '目前無角色資料' }"
        >
          <template #edit="{item, index}">
            <td class="py-2">
              <CButton
                color="dark"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="edit(item, index)"
              >
                編輯
              </CButton>
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                class="ml-1"
                @click="edit(item, index)"
              >
                刪除
              </CButton>
            </td>
          </template>
        </CDataTable>

        <div v-if="page != undefined">
          <CPagination
            :activePage.sync="page.current_page"
            :pages="page.last_page"
            align="end"
            @click.native="get(page.current_page)"
            v-if="page != ''"
          />
        </div>
      </CCardBody>
    </CCard>
    <div class="popMask " v-if="rolePop">
      <div class="pop_Modal ask_pop">
        <div class="popCon">
          <div class="close" @click="rolePop = false">
            <img src="../../assets/img/x.png" />
          </div>
          <div class="popAll">
            <div class="title">建立角色</div>
            <CCard>
              <CCardBody>
                <ValidationObserver tag="form" ref="form">
                  <CForm>
                    <validation-provider
                      rules="required"
                      v-slot="{ errors }"
                      name="角色名稱"
                    >
                      <CInput
                        label="角色名稱"
                        placeholder=""
                        class="col-sm-8"
                        v-model="roleName"
                      />
                      <div class="errMsg">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
            <div class="form-group form-actions positionR">
              <div class="sendButton">
                <div class="button floatRight"></div>
                <div class="button floatRight">
                  <CButton
                    block
                    color="dark"
                    variant="outline"
                    @click="sendBtn()"
                    >確認
                  </CButton>
                </div>

                <div class="clearBoth"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 共用錯誤彈窗 -->
    <Modal></Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Modal from '../Modal.vue'
export default {
  name: 'Clients',
  //   props: ['itemTable', 'fieldsTable'],
  data() {
    return {
      details: [],
      collapseDuration: 0,
      userAuth: sessionStorage.getItem('userAuth'),
      search: '',
      orderBy: '',
      sortedBy: '',
      selectDaily: 0,
      exchangeRate: [
        { id: 1, role: '管理者', status: '啟用' },
        { id: 2, role: '業務', status: '啟用' },
        { id: 3, role: '財務', status: '啟用' },
      ],
      page: { current_page: 1, last_page: 1 },
      rolePop: false,
      roleName: '',
    }
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters([
      'get_companies',
      'get_companiesPage',
      'get_inStock',
      'get_companiesAll',
    ]),
    fields() {
      return [
        { key: 'id', label: '編號' },
        { key: 'role', label: '名稱' },
        { key: 'status', label: '狀態' },

        // {
        //   key: 'edit',
        //   label: '',
        //   _style: 'width:20%',
        //   sorter: false,
        //   filter: false,
        // },
      ]
    },
    // 公司下拉
    selectOptions() {
      if (this.company) {
        return this.company.map((g) => ({
          label: g.name,
          id: g.id,
        }))
      }
    },
  },
  watch: {
    selectOptions() {
      $('.vs__search').attr('value', '請選擇')
    },
  },
  methods: {
    handlerSorterValueChange(o) {
      this.orderBy = o.column
      this.sortedBy = o.asc == true ? 'ASC' : 'DESC'
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },

    edit(item) {
      this.$router.push('/role/edit/' + item.id)
    },

    get(page) {
      let data = {
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: page,
      }
      this.$store.dispatch('actionCompanies', data)
    },

    getCompany() {
      let data = {
        companyName: this.search,
        orderBy: this.orderBy,
        sortedBy: this.sortedBy,
        page: 1,
      }
      this.$store.dispatch('actionCompanies', data)
    },
  },
  created() {
    // this.orderBy = 'created_at'
    // this.sortedBy = 'DESC'
    // let data = {
    //   orderBy: this.orderBy,
    //   sortedBy: this.sortedBy,
    // }
    // this.$store.dispatch('actionCompanies', data)
    // let dataCompany = {
    //   orderBy: '',
    //   sortedBy: 'DESC',
    // }
    // this.$store.dispatch('actionCompaniesAll', dataCompany)
  },
}
</script>
